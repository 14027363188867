import 'lazysizes';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';


const _si = (selector, context = document, returnNull = false) => {
	const tmp = context.querySelector(selector);
	return tmp ? tmp : returnNull ? null : _c('div');
};
const _s = (selector, context = document) => {
	return Array.apply(null, context.querySelectorAll(selector));
};

const _c = (elm) => {
	return document.createElement(elm);
};

const _ael = (sel, ev, callback) => {
	document.addEventListener(ev, (e) => {
		if (e.target === document) {
			return;
		}
		const that = e.target.closest(sel);
		if (that) {
			callback(that, e);
		}
	});
};

// App main
const main = async () => {};

// Execute async function
main().then(modules => {
	function init() {
		_ael('section header', 'click', (elm) => {
			elm.closest('section').classList.toggle('active');
		});
	}

	window.app = {
		categories() {
			return {
				open: window.openEntry
			}
		}
	}

	init();
	Alpine.plugin(collapse);
	Alpine.start();
});
